import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { Icon } from '@iconify/react';
import ReactTooltip from 'react-tooltip';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

import './style.scss';
import CustomAutoComplete from '../autocomplete/autocomplete';
import {
	apiSlice,
	useGetAllActiveCostCentersQuery,
	useGetOwnActiveCostCentersQuery,
} from '../../store/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
	commonState,
	selectHomeFilters,
	setCluster,
	setCostCenterFilter,
	setOwnCostCenter,
	setServiceOptions,
	setSorting,
} from '../../store/commonSlice';
import DisplayIcon from '../icon';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import PAGES from '../../utils/pages.enum';
import { IProp } from '../cost-center-card';
import { ISearchResult } from '../../types/common.interface';
import { TieredMenu } from 'primereact/tieredmenu';
import { RadioButton } from 'primereact/radiobutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import useDownloadFile from '../../hooks/useDownloadFile';
import { useParams } from 'react-router-dom';
import { Message } from 'primereact/message';
import { sortingFunction, uniqueList } from '../../utils/utility-function';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import useGetOU from '../../hooks/useGetOU';

const ExtraActions = function () {
	
	const OU = useGetOU();
	const {
		data = [],
		isLoading,
	} = useGetAllActiveCostCentersQuery({OU});

	const [targetResponsibleValue, setTargetResponsibleValue] = useState<any>();
	const sortOrder = [
		{ name: 'ASC', key: 'ASC' },
		{ name: 'DSC', key: 'DSC' },
	];
	const params = useParams();

	const costCenterId =
		params.costCenterId === undefined ? 0 : params.costCenterId;
	const { data: ownCostCenters = [], isLoading: isLoadingOwnCostCenters } =
		useGetOwnActiveCostCentersQuery({OU});

	const homeFilters = useSelector(selectHomeFilters);

	const searchTerm = useSelector(commonState).searchTerm;

	const [searchResult, setSearchResult] = useState<ISearchResult[]>();

	const [selectedCategory, setSelectedCategory] = useState<any>();
	const [selectedOrder, setSelectedOrder] = useState<any>();

	const filteredOptions: IProp[] = useMemo(() => {
		let output;
		output = homeFilters.ownCostCenter ? ownCostCenters : data;

		output =
			searchTerm !== ''
				? output.filter(
					(items) =>
						Object.values(items).findIndex((chunk) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchTerm.toLowerCase())
						) !== -1
				)
				: output;

		if (searchResult && searchResult.length > 0) {
			output = output.filter(
				(_item) =>
					searchResult.findIndex(
						(_i) => _i.costCenterId === _item.costCenterId
					) !== -1
			);
		}
		output =
			homeFilters.costCenterFilter.target_costcenter !== ''
				? output.filter(
					(d) =>
						d.costCenter === homeFilters.costCenterFilter.target_costcenter
				)
				: output;
		output =
			homeFilters.targetResponsilbeFilter.user_responsible !== ''
				? output.filter(
					(d) =>
						d.userResponsible ===
						homeFilters.targetResponsilbeFilter.user_responsible
				)
				: output;
		output =
			homeFilters.ccMasterFilter.ccMaster !== ''
				? output.filter(
					(d) =>
						d.ccMaster ===
						homeFilters.ccMasterFilter.ccMaster
				)
				: output;
		return output;
	}, [
		homeFilters.ownCostCenter,
		searchTerm,
		data,
		ownCostCenters,
		homeFilters.costCenterFilter,
		homeFilters.targetResponsilbeFilter,
		homeFilters.ccMasterFilter
	]);

	const currentPage = useFindCurrentLocation();
	const { downloadFile } = useDownloadFile();

	const sortableFields =
		(currentPage === PAGES.ITSERVICES || currentPage === PAGES.ITSERVICES_103 || currentPage === PAGES.PICAS || currentPage === PAGES.WORKPLACE_103)
			? ((currentPage === PAGES.PICAS || currentPage === PAGES.WORKPLACE_103)
				? [
					{ name: 'Cost Center', key: 'costCenter' },
					{ name: 'Cost Center Name', key: 'name' },
					{ name: 'Picas Amount', key: 'picasAmount' },
				]
				: [
					{ name: 'Cost Center', key: 'costCenter' },
					{ name: 'Cost Center Name', key: 'name' },
					{ name: 'Booked ILV', key: 'bookedAmount' },
					{ name: 'Planned CC Report', key: 'plannedAmount' },
				])
			: [
				{ name: 'SAID', key: 'said' },
				{ name: 'Service Name', key: 'serviceName' },
				{ name: 'Service Id', key: 'serviceId' },
				{ name: 'Additional Information', key: 'additionalInfo' },
				{ name: 'Booked ILV', key: 'bookedAmount' },
				{ name: 'Planned CC Report', key: 'plannedAmount' },
			];

	useEffect(() => {
		currentPage === PAGES.SERVICES || currentPage === PAGES.SERVICES_103 ?
			dispatch(setCostCenterFilter({ viewType: 'table' })) :
			dispatch(setCostCenterFilter({ viewType: 'list' }));
	}, [currentPage === PAGES.SERVICES, currentPage === PAGES.SERVICES_103])

	const filteredCostCenters = useSelector(commonState).filteredCostCenters;
	const dispatch = useDispatch();

	const isClusterEnabled = useSelector(commonState).clusterServices;
	const isFilterActive =
		useSelector(commonState).searchTerm !== '' ||
		homeFilters.costCenterFilter.target_costcenter !== '' ||
		homeFilters.targetResponsilbeFilter.user_responsible !== '' ||
		homeFilters.ccMasterFilter.ccMaster !== '' ||
		homeFilters.ownCostCenter;

	const justifyOptions =
		currentPage === PAGES.ITSERVICES ||
			currentPage === PAGES.ITSERVICES_103 ||
			currentPage === PAGES.PICAS ||
			currentPage === PAGES.WORKPLACE_103 ||
			currentPage === PAGES.ARCHIVE ||
			currentPage === PAGES.ARCHIVE_SERVICES
			? [
				{ icon: 'tablerList', value: 'list' },
				{ icon: 'tablerTable', value: 'table' },
			]
			: [
				{ icon: 'tablerList', value: 'list' },
				{ icon: 'tablerTable', value: 'table' },
				{ icon: 'tablerEdit', value: 'tableEdit' },
			];
	const clusterOptions = [
		{ icon: 'tablerNoCluster', value: false },
		{ icon: 'tablerCluster', value: true },
	];

	const ownCostCenterOptions = [
		{ icon: 'tablerUsers', value: false },
		{ icon: 'tablerUser', value: true },
	];

	const justifyTemplate = (option: any) => {
		return (
			<DisplayIcon
				icon={option.icon}
				className={`${option.value} button-group`}
			/>
		);
	};

	const formatResult = (item: any) => {
		return (
			<>
				<span style={{ display: 'block', textAlign: 'left' }}>
					id: {item.id}
				</span>
				<span style={{ display: 'block', textAlign: 'left' }}>
					name: {item.name}
				</span>
			</>
		);
	};

	const sort = useRef<OverlayPanel>(null);
	const itemTemplate = (item: any) => {
		return (
			<div className="option-item flex justify-content-between">
				<h4 className="left">{item.name}</h4>{' '}
				<h4 className="right">{item.target_costcenter}</h4>
			</div>
		);
	};
	const itemTemplate2 = (item: any) => {
		return (
			<div className="option-item">
				<h4 className="left">{item.target_responsible}</h4>
			</div>
		);
	};
	const ccMasterTemplate = (item: any) => {
		return (
			<div className="option-item">
				<h4 className="left">{item.ccMaster}</h4>
			</div>
		);
	};
	const filterCostCenter = (
		{
			name,
			target_costcenter,
		}: {
			name: string;
			target_costcenter: string;
		} = {
				name: '',
				target_costcenter: '',
			}
	) => {
		dispatch(
			setCostCenterFilter({ costCenterFilter: { name, target_costcenter } })
		);
		if (target_costcenter === '') {
			setTargetResponsibleValue(undefined);
			filterTagetResponsible();
		} else {
			const foundValue = filteredCostCenters.find(
				(_item) => _item.costCenter === target_costcenter
			);
			if (foundValue) {
				setTargetResponsibleValue({
					user_responsible: foundValue.userResponsible,
					target_responsible: foundValue.targetResponsible,
				});
			}
		}
	};
	const filterTagetResponsible = (
		{
			user_responsible,
			target_responsible,
		}: {
			user_responsible: string;
			target_responsible: string;
		} = {
				user_responsible: '',
				target_responsible: '',
			}
	) => {
		dispatch(
			setCostCenterFilter({
				targetResponsilbeFilter: { user_responsible, target_responsible },
			})
		);
	};

	const filterCCMaster = ({ccMaster} : {ccMaster: string} = {ccMaster: ''}) => {
		dispatch(
			setCostCenterFilter({ccMasterFilter: {ccMaster}})
		);
	};

	useEffect(() => {
		if (selectedCategory && selectedOrder) {
			if (currentPage === PAGES.ITSERVICES || currentPage === PAGES.PICAS || currentPage === PAGES.ITSERVICES_103 || currentPage === PAGES.WORKPLACE_103 || currentPage === PAGES.ARCHIVE) {
				dispatch(
					setSorting({
						sort: { field: selectedCategory.key, order: selectedOrder.key },
					})
				);
			} else {
				dispatch(
					setSorting({
						sortServices: {
							field: selectedCategory.key,
							order: selectedOrder.key,
						},
					})
				);
			}
		}
	}, [selectedCategory, selectedOrder]);

	return (
		<div
			className={`w-full flex flex-column mb-4 mt-4 lg:flex-row ${false ? 'justify-content-end' : 'justify-content-between'
				} pr-3 pl-3 align-items-center`}
		>
			<div
				className={`flex w-full lg:w-${currentPage === PAGES.ITSERVICES || currentPage === PAGES.PICAS || currentPage === PAGES.ITSERVICES_103 || currentPage === PAGES.WORKPLACE_103 || currentPage === PAGES.ARCHIVE ? 8 : 8
					} justify-content-start align-items-center`}
			>
				<div className="app-icon">
					<DisplayIcon icon="filter" />
				</div>
				{(currentPage === PAGES.ITSERVICES || currentPage === PAGES.PICAS || currentPage === PAGES.ITSERVICES_103 || currentPage === PAGES.WORKPLACE_103 || currentPage === PAGES.ARCHIVE) && (
					<>
						<div
							style={{ width: 250, marginLeft: '1rem' }}
							className="options-input"
						>
							{isLoading ? (
								<Skeleton height="2rem" className="mb-2"></Skeleton>
							) : (
								<CustomAutoComplete
									tooltip="Filter By Cost Center Name"
									tooltipOptions={{ position: 'top' }}
									customId="select-costcenter"
									placeholder="Select By Cost Center"
									itemTemplate={itemTemplate}
									inputList={sortingFunction(
										filteredCostCenters.map((item: IProp) => ({
											name: item.name,
											target_costcenter: item.costCenter,
										})),
										'name',
										'DSC'
									)}
									target="name"
									field="cc-name"
									callback={filterCostCenter}
									defaultValue={
										homeFilters.costCenterFilter.name === '' &&
											homeFilters.costCenterFilter.target_costcenter === ''
											? undefined
											: homeFilters.costCenterFilter
									}
								/>
							)}
						</div>
						<div
							style={{ width: 250, marginLeft: '1rem' }}
							className="options-input"
						>
							{isLoading ? (
								<Skeleton height="2rem" className="mb-2"></Skeleton>
							) : (
								<CustomAutoComplete
									tooltip="Filter By Cost Center Responsible"
									tooltipOptions={{ position: 'top' }}
									customId="select-target"
									placeholder="Select By Target Responsible"
									itemTemplate={itemTemplate2}
									inputList={uniqueList(
										sortingFunction(
											filteredCostCenters.map((item) => ({
												user_responsible: item.userResponsible,
												target_responsible: item.targetResponsible,
											})),
											'target_responsible',
											'DSC'
										),
										'user_responsible'
									)}
									field="target_responsible"
									target="target_responsible"
									overrideSelectedValue={targetResponsibleValue}
									defaultValue={
										homeFilters.targetResponsilbeFilter.target_responsible ===
											'' &&
											homeFilters.targetResponsilbeFilter.user_responsible === ''
											? undefined
											: homeFilters.targetResponsilbeFilter
									}
									callback={filterTagetResponsible}
								/>
							)}
						</div>
						{currentPage !== PAGES.ARCHIVE && 
						<div
							style={{ width: 250, marginLeft: '1rem' }}
							className="options-input"
						>
							{isLoading ? (
								<Skeleton height="2rem" className="mb-2"></Skeleton>
							) : (
								<CustomAutoComplete
									tooltip="Filter By CC Master"
									tooltipOptions={{ position: 'top' }}
									customId="select-cc-master"
									placeholder="Select By CC Master"
									itemTemplate={ccMasterTemplate}
									inputList={uniqueList(sortingFunction(
										filteredCostCenters.map((item) => ({
											ccMaster: item.ccMaster,
										})),
										'ccMaster',
										'DSC'
										),
										'ccMaster'
									)}
									field="ccMaster"
									target="ccMaster"
									defaultValue={
										homeFilters.ccMasterFilter.ccMaster === '' ? undefined : homeFilters.ccMasterFilter
									}
									callback={filterCCMaster}
								/>
							)}
						</div>
						}
					</>
				)}
				<div
					className="flex align-items-center sort-options ml-3 sorting-options"
					onClick={(e) => sort.current?.toggle(e)}
					data-pr-tooltip="Select Options To Sort"
					data-pr-position="top"
				>
					<DisplayIcon icon="tablerSort" className="mr-1" />
					{selectedCategory && selectedOrder ? (
						<div className="flex align-items-center">
							{selectedCategory.name} . {selectedOrder.name}
							<DisplayIcon
								icon="tablerCancel"
								className="sort-cancel ml-3"
								onClick={(e) => {
									e.stopPropagation();
									setSelectedOrder(undefined);
									setSelectedCategory(undefined);
								}}
							/>
						</div>
					) : (
						<span className="ml-2">Select Sorting Options ...</span>
					)}
				</div>
				<Tooltip target=".sorting-options" />
				<OverlayPanel
					ref={sort}
					id="overlay_panel"
					style={{ width: 'auto' }}
					className="overlaypanel-demo flex"
				>
					<div className="flex flex-column">
						<h5>Sort Field</h5>
						<div className="flex mt-1">
							{sortableFields.map((category) => {
								return (
									<div key={category.key} className="field-radiobutton mr-4">
										<RadioButton
											inputId={category.key}
											name="category"
											value={category}
											onChange={(e) => setSelectedCategory(e.value)}
											checked={selectedCategory?.key === category.key}
											disabled={category.key === 'R'}
										/>
										<label htmlFor={category.key}>{category.name}</label>
									</div>
								);
							})}
						</div>
						<div className="flex flex-column">
							<h5>Sort Order</h5>
							<div className="flex mt-1">
								{sortOrder.map((category) => {
									return (
										<div key={category.key} className="field-radiobutton mr-4">
											<RadioButton
												inputId={category.key}
												name="category"
												value={category}
												onChange={(e) => setSelectedOrder(e.value)}
												checked={selectedOrder?.key === category.key}
												disabled={category.key === 'R'}
											/>
											<label htmlFor={category.key}>{category.name}</label>
										</div>
									);
								})}
							</div>
						</div>

						{selectedCategory && !selectedOrder ? (
							<Message severity="info" text="Please Select Sort Order" />
						) : null}
					</div>
				</OverlayPanel>
			</div>

			<div
				className={`flex w-full lg:w-${currentPage === PAGES.ITSERVICES || currentPage === PAGES.PICAS || currentPage === PAGES.ITSERVICES_103 || currentPage === PAGES.WORKPLACE_103 || currentPage === PAGES.ARCHIVE ? 4 : 4
					} mt-4 lg:mt-0 ${false ? 'justify-content-end' : 'justify-content-around'
					}`}
			>
				{(currentPage === PAGES.SERVICES || currentPage === PAGES.SERVICES_103 ||
					currentPage === PAGES.ARCHIVE_SERVICES) && (
						<SelectButton
							value={isClusterEnabled}
							options={clusterOptions}
							onChange={(e) => dispatch(setCluster())}
							itemTemplate={justifyTemplate}
							className="pi-scale"
							tooltip="Enable Clustering Of Services"
							tooltipOptions={{ position: 'top' }}
						/>
					)}

				{(currentPage === PAGES.ITSERVICES || currentPage === PAGES.PICAS || currentPage === PAGES.ITSERVICES_103 || currentPage === PAGES.WORKPLACE_103 || currentPage === PAGES.ARCHIVE) && (
					<SelectButton
						value={homeFilters.ownCostCenter}
						options={ownCostCenterOptions}
						onChange={(e) => {
							dispatch(setOwnCostCenter({ ownCostCenter: e.value }));
						}}
						itemTemplate={justifyTemplate}
						className="pi-scale"
						tooltip="Display Cost Center Owned By The Current User"
						tooltipOptions={{ position: 'top' }}
					/>
				)}

				<SelectButton
					value={homeFilters.viewType}
					options={justifyOptions}
					onChange={(e) => {
						dispatch(setCostCenterFilter({ viewType: e.value }));
					}}
					itemTemplate={justifyTemplate}
					className="pi-scale"
					tooltip="Select View Type ie. List, Table or Edit Mode"
				/>
				{(currentPage === PAGES.SERVICES || currentPage === PAGES.SERVICES_103 || currentPage === PAGES.ARCHIVE) && (
				<div className="icon-section flex align-items-center">
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'top' }}
						tooltip="Export Cost Centers Displayed In The Page"
					>
						<DisplayIcon
							icon="excel"
							className="excel-icon"
							onClick={() =>
								downloadFile(
									`actuals/actualsexport?ccId=${costCenterId}&OU=${OU}`,
									'Actuals-Report_' + new Date().toISOString() + '.xlsx'
								)
							}
						/>
					</Button>
				</div>
				)}
			</div>

			<ReactTooltip type="light" />
		</div>
	);
};

export default ExtraActions;
